<template>
	<div>
	<nav class="sitemap">
		<div class="inner">
			<h2 class="sitemap_tit"><span class="blind">Sitemap</span></h2>
			<div class="gnb sitemap_menu">
				<ul class="dep1_list">

					<template v-if="!isGuest">
						<li class="dep1_item">
							<a href="/support/serviceInfo" class="dep1_link">서비스 소개</a>
						</li>
						<li class="dep1_item">
							<a href="/service/info" class="dep1_link">서비스 신청</a>
						</li>
						<li class="dep1_item">
							<a href="#" class="dep1_link">MY 서비스</a>
							<ul class="dep2_list">
								<li class="dep2_item"><a href="/myService/myList" class="dep2_link">나의 서비스</a></li>
								<li class="dep2_item"><a href="/myService/analysis" class="dep2_link">분석 보고서</a></li>
								<li class="dep2_item"><a href="/myService/mornitoring" class="dep2_link">모니터링 보고</a></li>
								<li class="dep2_item"><a href="/myService/paymentOption" class="dep2_link">결제정보관리</a></li>
								<li class="dep2_item"><a href="/myService/inviteList" class="dep2_link">초대하기</a></li>
							</ul>
						</li>
						<li class="dep1_item">
							<a href="#" class="dep1_link">고객센터</a>
							<ul class="dep2_list">
								<li class="dep2_item"><a href="/board/cms_notice" class="dep2_link">공지사항</a></li>
								<li class="dep2_item"><a href="/faq" class="dep2_link">FAQ</a></li>
								<li class="dep2_item"><a href="/support/qna" class="dep2_link">문의하기</a></li>
							</ul>
						</li>
						<li class="dep1_item">
							<a href="/review/cms_board" class="dep1_link">사용후기</a>
						</li>
					</template>

					<template v-if="isGuest">
						<li class="dep1_item">
							<a href="/myService/analysis" class="dep1_link">분석 보고서</a>
						</li>
						<li class="dep1_item">
							<a href="/myService/mornitoring" class="dep1_link">모니터링 보고</a>
						</li>
					</template>

				</ul>
			</div>
			<button type="button" class="btn_close"><span class="blind">닫기</span></button>
		</div>
	</nav>
	<footer class="footer">
		<div class="inner">
			<h2 class="logo"><a href="/">WAPU</a></h2>
			<div class="cs_info">
				<span class="tit">빠른상담</span>
				<strong class="tel">010-2448-3054</strong>
				<ul class="time">
					<li>
						<span class="lt">평일</span>
						<span class="rt">08:00 ~ 16:00</span>
					</li>
					<li>
						<span class="lt">점심 시간</span>
						<span class="rt">11:30 ~ 13:00</span>
					</li>
					<li>
						<span class="lt">메일&emsp;</span>
						<span class="rt" style="font-size: 13px">WISENPURE@ELAND.CO.KR</span>
					</li>
				</ul>
			</div>
			<nav class="fnb">
				<ul class="fnb_list">
					<li class="fnb_item">
						<a href="/support/company" class="fnb_link">회사소개</a>
					</li>
					<li class="fnb_item">
						<a href="/support/terms" class="fnb_link">이용약관</a>
					</li>
					<li class="fnb_item">
						<a href="/support/privacy" class="fnb_link">개인정보처리방침</a>
					</li>
					<li class="fnb_item">
						<a href="/support/email" class="fnb_link">이메일무단수집거부</a>
					</li>
				</ul>
			</nav>
			<div class="copyright">
				<p>상호명: 주식회사 이랜드이노플</p>
				<p>대표이사 : 김지원</p>
				<p>사업자등록번호: 105-81-45524</p>
				<p>서울시 강서구 마곡동로146, 마곡동 마곡이랜드글로벌알앤디센터.</p>
				<p>copyright 2002 ⓒ WISENPURE ALL Rights reserved.</p>
			</div>
		</div>
	</footer>
	</div>
</template>

<script>

export default {
	name: "partialFooter",
	mixins: [
		// Swiper
	],
	data() {


		return {
			boardKey: "site_qna",
			boardInfoVO: new this.BoardInfoVO(),
			boardVO: new this.BoardVO(this.getSessionProc()),

			isGuest: false,
		}
	},
	created() {

		// this.getCompanyInfo();
		this.getBoardInfo();
	},
	mounted() {

		if( this.isLogin() ){
			if(this.getSessionProc().isGuest) {
				this.isGuest = this.getSessionProc().isGuest
			}
		}

		this.$eventBus.$emit("initApp");

	},
	updated() {

	},
	methods: {
		snsOpen(url) {
			const temp = {
				isCloseBTN : "Y",
				url: url,
			}
			this.appOpen( temp );
		},


		getBoardInfo () {

			this.axiosCaller.get( this, this.APIs.BOARDS + "/getBoardInfo", {
				boardKey: this.boardKey
			}, (res) => {
				var result = res.data
				if ( result.status === "ok" ) {
					this.boardInfoVO = result.boardInfoVO
				}
			});
		},


		setBoard(e) {

			e.preventDefault()

			this.boardVO.boardKey = this.boardKey
			this.boardVO.contentHtml = this.boardVO.contentText
			this.boardVO.writerKey = this.getLoginId()
			if ( this.isLogin() ) {
				this.boardVO.writer = this.getSessionProc().userName
			} else {
				this.boardVO.writer = this.getLoginId()
			}

			// 문의 메일 발송
			this.boardVO.isSendMail = true

			this.$eventBus.$emit("startProgress")
			this.axiosCaller.post( this, this.APIs.BOARDS + "/setBoardOne", this.boardVO, (res) => {
				this.$eventBus.$emit("doneProgress")
				const result = res.data
				console.log( result )
				if ( result.status === "ok" ) {
					this.swalUtils.gritter("Success", "문의 내용이 등록되었습니다.", "success", 800)
					this.boardVO = new this.BoardVO();
				}
			})

		},


	},
}
</script>

<style scoped>

</style>
